import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
// import DateFilterr from "./DateFilter";
// import { format } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";
import { ScheduleContext } from "../../../context/messages/ScheduleContext";

const gridStyle = { minHeight: 550 };

window.moment = moment;
type FilterValue = {
  name: string;
  operator: string;
  type: string;
  value: string;
};

const SchedualDataGrid: React.FC<{ enableFiltering?: boolean }> = ({
  enableFiltering = true,
}) => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const [cellSelection] = useState({ "2,name": true });
  // const [enableFiltering] = useState(true);
  // const [filteredMessages, setFilteredMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const statuses = [
    { id: "delivered", label: "مرسلة" },
    { id: "pending", label: "قيد الانتضار" },
    // { id: "pending", label: "All" },
  ];
  // Initialize filters with default values
  const defaultFilterValue: FilterValue[] = [
    { name: "status", operator: "inlist", type: "select", value: "" },
    { name: "updated_at", operator: "inrange", type: "date", value: "" },
    { name: "body", operator: "contains", type: "string", value: "" },
    { name: "contacts", operator: "contains", type: "string", value: "" },
    { name: "title", operator: "contains", type: "string", value: "" },
    { name: "device", operator: "contains", type: "string", value: "" },
  ];
  
  const [filters, setFilters] = useState<FilterValue[]>(defaultFilterValue);

  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

 
  const columns = [
    {
      name: "title",
      minWidth: 170,
      header: "العنوان",
      enableColumnFilterContextMenu: false,
    },
    {
      minWidth: 160,
      name: "device",
      header: "الجهاز",
      enableColumnFilterContextMenu: false,
    },
    {
      minWidth: 190,
      name: "contacts",
      header: "جهات الاتصال",
      enableColumnFilterContextMenu: false,
    },
    {
      name: "body",
      header: "الرسالة",
      enableColumnFilterContextMenu: false,
      minWidth: 220,
      render: ({ value }: { value: string }) => {
        try {
          const parsedMessage = JSON.parse(value);

          if (parsedMessage.image) {
            return (
              <div>
                <img
                  src={parsedMessage.image.url}
                  alt="Message"
                  className="w-20 h-auto cursor-pointer"
                  onClick={() => {
                    setSelectedImage(parsedMessage.image.url);
                    setIsModalOpen(true);
                  }}
                />
                {parsedMessage.caption && <p>{parsedMessage.caption}</p>}
              </div>
            );
          }

          if (parsedMessage.text) {
            return <p>{parsedMessage.text}</p>;
          }

          return <p>Unknown message format</p>;
        } catch (error) {
          return <p>Invalid message format</p>;
        }
      },
    },

    {
      name: "status",
      header: "الحالة",
      enableColumnFilterContextMenu: false,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: statuses,
      },
      render: ({ value }: { value: number | string }) => {
        // Check if value is valid (number or string)
        if (value === "delivered") {
          return (
            <div className="cursor-pointer">
              <p>مرسلة</p>
            </div>
          );
        } else {
          return (
            <div className="cursor-pointer">
              <p>قيد الانتظار</p>
            </div>
          );
        }
      },
    },
    {
      name: "updated_at",
      header: "تاريخ الارسال",
      minWidth: 290,
      enableColumnFilterContextMenu: false,
      filterEditor: DateFilter,
      // filterEditorProps: {
      //   dateFormat: "MM-DD-YYYY",
      //   placeholder: "Select date",
      // },
      filterEditorProps: (_props: any, { index }: { index: number }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: "MM-DD-YYYY",
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index === 1 ?  "تاريخ البدابة..." :"الى الان...",
        };
      },
      // render: ({ value }: { value: string }) =>
      //   moment(value).format("MM-DD-YYYY"),
      render: (
        { value }: { value: string },
        { dateFormat }: { dateFormat: string }
      ) => moment(value).format(dateFormat),
    },
  ];

  // const filterMessagesByDate = useCallback(
  //   (startDate: string, endDate: string) => {
  //     if (!context || !context.schedules?.Schedules) return;

  //     const filtered = context.schedules?.filter((message: any) => {
  //       const messageDate = new Date(message.schedule_at);
  //       return (
  //         messageDate >= new Date(startDate) && messageDate <= new Date(endDate)
  //       );
  //     });

  //     setFilters(filtered);
  //   },
  //   [context]
  // );

  const exportToExcel = () => {
    const worksheetData = [
      // Define Arabic headers
      ["جهات الاتصال", "الجهاز", "الرسالة", "عنوان الرسالة", "الحالة", "تاريخ الجدولة"],
      ...(dataSource ?? []).map((message) => [
        message.contacts,
        message.device,
        message.body,
        message.title,
        message.status === "delivered" ? "مرسلة" : "قيد الانتضار",
        moment(message.schedule_at).format("YYYY-MM-DD"),
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Shcedual-Messages");

    // Styling: Add RTL and header styles
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 60 },
      { wch: 10 },
      { wch: 20 },
    ]; // Set column widths
    worksheet["A1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };
    worksheet["B1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };

    XLSX.writeFile(workbook, "Shcedual-Messages.xlsx");
  };

  const { schedules, loading,fetchSchedules } = context;
  useEffect(() => {
    const filters = {
      status: "",
      from_date: "",
      to_date: "",
      to_number: "",
      type: "",
    };
    fetchSchedules(filters); 
  }, [fetchSchedules]);

  if (loading) {
    return <div className="max:w-6xl py-12 px-16">Loading Reports schedual...</div>;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  // console.log("messages data",schedules?.Schedules)

  const dataSource = Array.isArray(schedules?.Schedules)
    ? schedules?.Schedules.map((message) => ({
        id: message.id,
        title: message.title,
        body: message.body,
        device: message?.device?.name,
        message: message.message,
        contacts: message.contacts.map((contact) => contact.name).join(', '),
        status: message.status,
        schedule_at: message.schedule_at,
      }))
    : [];

  // console.log("schedules data",dataSource)

  // yyyy-MM-dd'T'HH:mm:ss.SSSSSS
  return (
    <div className="mt-16 mx-5 overflow-y-auto">
      <div className="sm:flex gap-2 justify-end grid">
      {(dataSource ?? []).length > 0 && (
          <div className="my-2">
            <button
              onClick={exportToExcel}
              className="px-4 py-2.5 sm:mx-0 mx-1 bg-blue-500 text-white rounded"
            >
              Excel استخراج
            </button>
          </div>
        )}
        {/* <DateFilterr onDateFilterChange={filterMessagesByDate} /> */}
      </div>
      <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        columns={columns}
        enableFiltering={enableFiltering}
        pagination
        cellSelection={cellSelection}
        defaultLimit={10}
        rowHeight={150}
        showZebraRows={true}
        dataSource={dataSource  || []}
        defaultFilterValue={filters}
      />
    </div>
  );
};

export default SchedualDataGrid;
