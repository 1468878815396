import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

// interface Users {
//   id: string;
//   user_id: number;
//   device_id: string;
//   To_number: string;
//   created_at: string;
//   updated_at: string;
// }

// interface UseriDData {
//   [x: string]: any;
//   current_page: number;
//   // Schedules: Users[];
// }

interface DashbordContextType {
  dashboardData: any | null;
  // dashboardDataId: any | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDashbordData: () => void;
  error: string | null;
  loading: boolean;
}

export const DashbordContext = createContext<DashbordContextType>({
  dashboardData: null,
  // dashboardDataId: null,
  loading: false,
  setLoading: () => {},
  error: null,
  fetchDashbordData: () => {},
});

const DashbordProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [dashboardData, setdashboardData] = useState<any | null>(null);
  // const [dashboardDataId, setdashboardDataId] = useState<UseriDData | null>(null);
  const [loading, setLoading] = useState(true);
  // const [loadings, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // Fetch the latest device master data
  const fetchDashbordData = useCallback(async () => {
    if (!token) return;
    // setLoading(true);
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/Setting/dashboardData",
        new FormData(),
        {
          headers: {
            "auth-token": token,
          },
          params: {
            token,
          },
        }
      );
      setdashboardData(response.data);
    //   console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch user master data", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchDashbordData();
  }, [fetchDashbordData]);

  return (
    <DashbordContext.Provider
      value={{
        dashboardData,
        loading,
        error,
        setLoading,
        // dashboardDataId,
        fetchDashbordData,
      }}
    >
      {children}
    </DashbordContext.Provider>
  );
};

export default DashbordProvider;
