import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";
import axios from "axios";

interface Device {
  id: number;
  id_users: number;
  deviceId: string;
  number: string;
  hook_url: string;
  hook_status: number;
  payment_status: number;
  Delay_time: number;
  Token: string;
  name: string;
  description: string;
  multidevice: string | null;
  plan_id: number;
  Periode_id: number;
  ExpiredDate: string;
  try_no: number;
  created_at: string;
  updated_at: string;
  status: string;
}

interface Contacts {
  pkId: number;
  sessionId: string;
  id: number;
  name: string;
  notify: string | null;
  verifiedName: string | null;
  imgUrl: string | null;
  status: string | null;
  code: string | null;
  pivot: {
    schedulemessage_id: number;
    contact_id: string;
  };
}

interface SechualData {
  totalSchedule: string;
  pendingSchedule: number;
  deliveredSchedule: number;
  failedSchedule: number;
  schedule: Schedule[];
}
interface Schedule {
  id: string;
  user_id: number;
  device_id: string;
  To_number: string;
  template_id: string | null;
  title: string;
  body: string;
  message: string;
  Active: 0 | 1;
  file: string;
  schedule_at: string;
  zone: string;
  date: string;
  time: string;
  status: string;
  repetition_type: number;
  repetition_amount: number;
  created_at: string;
  updated_at: string;
  device: Device;
  contacts: Contacts[];
}

interface ScheduleData {
  [x: string]: any;
  current_page: number;
  Schedules: Schedule[];
}

interface ScheduleContextType {
  schedules: ScheduleData | null;
  scheduled: SechualData | null;
  loading: boolean;
  error: string | null;
  fetchSchedules: (filters?: {
    status?: string;
    from_date?: string;
    to_date?: string;
    to_number?: string;
    type?: string;
  }) => Promise<void> | null; 
  // fetchSchedules: () => Promise<void> | null;
  fetchScheduleById: (id: string) => Promise<void> | null;
  setLoadingS: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ScheduleProviderProps {
  children: ReactNode;
}

export const ScheduleContext = createContext<ScheduleContextType | undefined>(
  undefined
);

const ScheduleProvider: React.FC<ScheduleProviderProps> = ({ children }) => {
  const [schedules, setSchedules] = useState<ScheduleData | null>(null);
  const [scheduled, setSchedulesd] = useState<SechualData | null>(null);
  const [loading, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useContext(AuthContext);

  const fetchSchedules = useCallback(
    async (filters?: {
      status?: string;
      from_date?: string;
      to_date?: string;
      to_number?: string;
      type?: string;
    }) => {
    const url = "https://whats1.sky.sky4system.com/api/user/Get-Schedule";

    const formData = new FormData();

    const headers: HeadersInit = {};

    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          ...(token ? { "auth-token": token } : {}),
        },
        params: {
          token,
        },
      });
      if (!response) {
        throw new Error(`HTTP error! status: ${response}`);
      }

      const data = await response.data;

      // console.log(data.data);

      if (data.status === true) {
        setSchedules(data.data);
        setSchedulesd(data.data);
      } else {
        setError(data.msg || "Failed to fetch schedules");
      }
    } catch (err) {
      setError(
        "An error occurred while fetching the schedules, Internal Server Error 500."
      );
    } finally {
      setLoadingS(false);
    }
  }, [token]);

  const fetchScheduleById = useCallback(
    async (id: string) => {
      const url =
        "https://whats1.sky.sky4system.com/api/user/GET-Schedule-byid";
      const headers: HeadersInit = {};
      const formData = new FormData();
      formData.append("id", id);

      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          params: {
            token,
          },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;

        if (data.status === true && data.data.length > 0) {
          // console.log(data);
          setSchedulesd(data.data);
          return data.data[0];
        } else {
          setError(data.msg || "Failed to fetch the schedule by ID");
          return null;
        }
      } catch (err) {
        setError("An error occurred while fetching,Internal Server Error 500.");
        return null;
      }
    },
    [token]
  );

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  return (
    <ScheduleContext.Provider
      value={{
        schedules,
        scheduled,
        loading,
        error,
        setLoadingS,
        fetchScheduleById,
        fetchSchedules,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleProvider;
