import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DeviceSelect from "./data/DeviceSelect";
import { FaRegWindowClose } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import GroupsSelect from "./data/GroupsSelect";
import { DashbordContext } from "../../../context/DashbordContext";

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}

const MediaSendGroup: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const [groupError, setGroupError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [mediaFile, setMediaFile] = useState<File | null>(null);
  
  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  // const { fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { dashboardData } = useContext(DashbordContext);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setMediaFile(file);
      await fileUpload(file);
    }
  };

  const handleSendBulkMessage = async () => {
    resetErrors();

    if (!validateInputs()) return;

    setIsLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const allNumbers = [...selectedGroup];

      if (allNumbers.length === 0) {
        throw new Error("No groups selected");
      }

      const batchSize = 10;
      for (let i = 0; i < allNumbers.length; i += batchSize) {
        const batch = allNumbers.slice(i, i + batchSize);

        // Use Promise.all to send all messages in the current batch
        await Promise.all(
          batch.map(async (number: string) => {
            await sendMessageWithRetry(number);
          })
        );

        // Add a delay between batches to avoid rate limits
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Adjust delay as needed
      }
      dashboardData()
      return allNumbers;
    } catch (error) {
      console.log("Error sending messages:", error);

      setSendResults([
        {
          number: "",
          status: "error",
          message: "Error occurred while sending",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessageWithRetry = async (number: string, retries = 3) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      const result = await sendMessage(number);
      if (result.status === "success") return result;
      console.log(
        `Retrying message to ${number} (Attempt ${attempt} of ${retries})`
      );
    }
    return {
      number,
      status: "error",
      message: "Failed after multiple attempts",
    };
  };

  const handleResend = async (number: string, index: number) => {
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index ? { ...result, isLoadingr: true, message: "" } : result
      )
    );
    const response = await sendMessage(number);
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index
          ? {
              ...result,
              isLoadingr: false,
              status: response.status,
              message: response.message,
            }
          : result
      )
    );
  };

  const clearMessages = () => setSendResults([]);

  const resetErrors = () => {
    setGroupError(null);
    setMessageError(null);
    setDeviceError(null);
    setSendResults([]);
  };

  const validateInputs = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب تحديد جهاز.");
      return false;
    }
    if (!selectedGroup || selectedGroup.length === 0) {
      setGroupError("يجب تحديد مجموعة واتس اب.");
      return false;
    }
    if (!mediaFile) {
      setMessageError("يجب اختيار ملف.");
      return false;
    }
    return true;
  };

  const validselectfile = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب تحديد جهاز.");
      return false;
    }
    return true;
  };

  const sendMessage = async (number: string): Promise<SendResult> => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    const formData = new FormData();
    formData.append("deviceToken", selectedDevice?.Token);
    formData.append("deviceId", selectedDevice?.deviceId);
    formData.append("url", `${fileUrl}`);
    formData.append("message", `${message}`);
    formData.append("Groupid", number);

    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/group/SendMediaUrl",
        formData
      );
      // console.log(response.data);
      // console.log(number)
      const result = getSendResult(response.data, number);
      setSuccessSend((await result).successSend);

      return (await result).sendResult;
    } catch (error) {
      console.log("Error sending message:", error);
      return { number, status: "error", message: "فشل في إرسال الرسالة" };
    }
  };

  const fileUpload = async (file: File) => {
    resetErrors();
    if (!validselectfile()) return;
    setIsLoadingUpload(true);
    const formData = new FormData();
    formData.append("deviceToken", selectedDevice?.Token);
    formData.append("deviceId", selectedDevice?.deviceId);
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/saveUrl",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total!
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      setFileUrl(response.data.url);
      // console.log(response.data);
    } catch (error) {
      console.log("Error uploading file:", error);
      // Handle the error message here
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status) {
      result = {
        number,
        status: "success",
        message: `تمت عملية الإرسال`,
      };
      successSend = {
        text: `تمت عملية الإرسال`,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
      };
      successSend = {
        text: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: `المشرفين فقط يمكنهم الارسال`,
      };
      successSend = { text: `المشرفين فقط يمكنهم الارسال`, type: "error" };
    } else {
      result = { number, status: "error", message: `فشل في إرسال الرسالة` };
      successSend = { text: `فشل في الإرسال إلى`, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  useEffect(() => {
    if (successSend) {
      const timeout = setTimeout(() => setSuccessSend(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [successSend]);

  const isImage = (url: string | null): boolean => {
    return !!url?.match(/\.(jpeg|jpg|gif|png|webp|svg|bmp)$/i);
  };

  return (
    <div className="mt-8 mx-2 flex gap-4 overflow-y-scroll hide-scrollbar">
      {sendResults.length > 0 && (
        <div>
          <div
            className="absolute top-16 right-8 w-auto p-4 bg-gray-700 text-white overflow-y-scroll text-sm scrollbar-light rounded-lg shadow-lg z-50 opacity-75"
            style={{ maxHeight: "30rem" }}
          >
            <button onClick={clearMessages} className="cursor-pointer">
              <FaRegWindowClose className="p-1 text-4xl text-blue-600" />
            </button>
            <ul>
              {sendResults.map((result, index) => (
                <li key={index} className="mb-2 flex items-center">
                  <span className="mr-2">{result.message}</span>
                  {result.status === "error" && (
                    <>
                      <FiRefreshCcw
                        onClick={() => handleResend(result.number, index)}
                        className={`ml-2 cursor-pointer ${
                          result.isLoadingr ? "animate-spin" : ""
                        }`}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className="sm:w-10/12 mx-auto w-full">
        <div>
          <button
            onClick={handleSendBulkMessage}
            className="px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transitiondisabled:opacity-50"
            disabled={isLoading || isLoadingUpload}
          >
            <span>{isLoading ? "جاري الإرسال..." : "إرسال"}</span>
          </button>
        </div>
        <div className="mb-6">
          <DeviceSelect onDeviceSelect={setSelectedDevice} />
          {deviceError && <p className="text-red-500 text-sm">{deviceError}</p>}
        </div>

        <div className="mb-4">
          <GroupsSelect
            onGroupsSelect={setSelectedGroup}
            selectedOptions={selectedGroup}
            selectedDeviceId={selectedDevice?.deviceId}
          />
          {groupError && <div className="text-red-500">{groupError}</div>}
        </div>

        <div className="mt-6">
          <div className="flex gap-6">
            <label className="lex-inline">
              <input
                type="file"
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.rtf,.json,.xml,.html,.css,.js,.zip,.rar,.tar,.gz,.7z,.bmp,.tiff,.webp,.svg,.mpg,.mpeg,.avi,.mov,.wmv,.flv,.mkv,.ogg,.wav,.aac,.flac,.m4a,.epub,.md,.yaml,.yml,.psd,.ai,.indd"
                onChange={handleFileChange}
                className="block w-auto mb-4 text-sm text-slate-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-violet-700
            hover:file:bg-violet-100
          "
              />
            </label>
            <div>
              {isImage(fileUrl) ? (
                <img
                  className="h-16 object-cover"
                  src={fileUrl || undefined}
                  alt="Uploaded content"
                />
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={fileUrl || undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              )}
            </div>
            {isLoadingUpload && (
              <div className="flex gap-6 w-[20rem] items-center">
                <div className="w-[20rem] bg-gray-200 rounded-full h-1">
                  <div
                    className="bg-blue-600 h-1 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <span className="ml-4 text-sm text-gray-700">
                  {uploadProgress}%
                </span>
              </div>
            )}
          </div>

          {messageError && (
            <p className="text-red-500 text-sm">{messageError}</p>
          )}
        </div>

        <div className="mb-6">
          <textarea
            rows={7}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-8 w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-cyan-500"
            placeholder="...وصف الملف"
          />
        </div>
      </div>
    </div>
  );
};

export default MediaSendGroup;
