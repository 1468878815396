import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { MessageApiContext } from "../../../context/messages/MessageApiContext";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import DateFilterr from "./DateFilter";
import { Link } from "react-router-dom";
// import { format } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";

const gridStyle = { minHeight: 550 };

window.moment = moment;
type FilterValue = {
  name: string;
  operator: string;
  type: string;
  value: string;
};

const DataGridFaulSMS: React.FC<{ enableFiltering?: boolean }> = ({
  enableFiltering = true,
}) => {
  const context = useContext(MessageApiContext);
  const [cellSelection] = useState({ "2,name": true });
  // const [enableFiltering] = useState(true);
  // const [filteredMessages, setFilteredMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const statuses = [
    // { id: "pending", label: "مرسلة" },
    { id: "failed", label: "فاشلة" },
    // { id: "pending", label: "All" },
  ];
  // Initialize filters with default values
  const defaultFilterValue: FilterValue[] = [
    { name: "status", operator: "inlist", type: "select", value: "failed" },
    { name: "updated_at", operator: "inrange", type: "date", value: "" },
    { name: "message", operator: "contains", type: "string", value: "" },
    { name: "from", operator: "contains", type: "string", value: "" },
    { name: "to", operator: "contains", type: "string", value: "" },
  ];
  const [filters, setFilters] = useState<FilterValue[]>(defaultFilterValue);

  const columns = [
    {
      name: "from",
      minWidth: 220,
      header: "المرسل",
      enableColumnFilterContextMenu: false,
    },
    {
      minWidth: 190,
      name: "to",
      header: "المستلم",
      enableColumnFilterContextMenu: false,
    },
    {
      name: "message",
      header: "الرسالة",
      enableColumnFilterContextMenu: false,
      minWidth: 220,
      render: ({ value }: { value: string }) => {
        try {
          const parsedMessage = JSON.parse(value);

          if (parsedMessage.image) {
            return (
              <div>
                <img
                  src={parsedMessage.image.url}
                  alt="Message"
                  className="w-20 h-auto cursor-pointer"
                  onClick={() => {
                    setSelectedImage(parsedMessage.image.url);
                    setIsModalOpen(true);
                  }}
                />
                {parsedMessage.caption && <p>{parsedMessage.caption}</p>}
              </div>
            );
          }

          if (parsedMessage.text) {
            return <p>{parsedMessage.text}</p>;
          }

          return <p>Unknown message format</p>;
        } catch (error) {
          return <p>Invalid message format</p>;
        }
      },
    },

    {
      name: "updated_at",
      header: "تاريخ الارسال",
      minWidth: 250,
      enableColumnFilterContextMenu: false,
      filterEditor: DateFilter,
      filterEditorProps: (_props: any, { index }: { index: number }) => {
        return {
          dateFormat: "MM-DD-YYYY",
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index === 1 ? "تاريخ البدابة..." : "الى الان...",
        };
      },
      render: (
        { value }: { value: string },
        { dateFormat }: { dateFormat: string }
      ) => moment(value).format(dateFormat),
    },
    // {
    //   name: "status",
    //   header: "الحالة",
    //   enableColumnFilterContextMenu: false,
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: "All",
    //     dataSource: statuses,
    //   },
    //   render: ({ value }: { value: number | string }) => {
    //     // Check if value is valid (number or string)
    //     if (value === "pending") {
    //       return (
    //         <div className="cursor-pointer">
    //           <p>مرسلة</p>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="cursor-pointer">
    //           <p>فاشلة</p>
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      name: "id",
      minWidth: 10,
      header: "Actions",
      render: ({ value }: { value: number | string }) => {
        // Check if value is valid (number or string)
        if (value) {
          return (
            <div className="cursor-pointer text-blue-500">
              <Link to={`/messages/${value}`}>
                <p>إعادة الارسال</p>
              </Link>
            </div>
          );
        }
        // Fallback if no valid id is found
        return <p>No ID available</p>;
      },
    },
  ];

  const filterMessagesByDate = useCallback(
    (startDate: string, endDate: string) => {
      if (!context || !context.Messages) return;

      const filtered = context.Messages?.filter((message: any) => {
        const messageDate = new Date(message.updated_at);
        return (
          messageDate >= new Date(startDate) && messageDate <= new Date(endDate)
        );
      });

      setFilters(filtered);
    },
    [context]
  );

  const exportToExcel = () => {
    const worksheetData = [
      // Define Arabic headers
      ["المرسل", "المستلم", "الرسالة", "الحالة", "تاريخ الارسال"],
      ...dataSource.map((message) => [
        message.from,
        message.to,
        message.message,
        message.status === "pending" ? "مرسلة" : "فاشلة",
        moment(message.updated_at).format("YYYY-MM-DD"),
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Messages");

    // Styling: Add RTL and header styles
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 60 },
      { wch: 10 },
      { wch: 20 },
    ]; // Set column widths
    worksheet["A1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };
    worksheet["B1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };

    XLSX.writeFile(workbook, "Failure-Messages.xlsx");
  };

  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

  const { fetchMessages, Messages, loading } = context;

  useEffect(() => {
    const filters = {
      status: "",
      from_date: "",
      to_date: "",
      to_number: "",
      type: "",
    };
    fetchMessages(filters);
  }, [fetchMessages]);

  if (loading) {
    return <div className="max:w-6xl py-12 px-16">Loading Reports...</div>;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  // console.log("messages data",Messages)

  const dataSource = Array.isArray(Messages)
    ? Messages.map((message) => ({
        id: message.id,
        from: message.from,
        to: message.to,
        device_id: message.device_id,
        message: message.message,
        status: message.status,
        updated_at: message.updated_at,
      }))
    : [];
  // console.log("messages data",Messages)

  // yyyy-MM-dd'T'HH:mm:ss.SSSSSS
  return (
    <div className="mt-16 mx-5 overflow-y-auto">
      <div className="text-right">
        <span>الرسائل الفاشلة</span>
      </div>
      <div className="sm:flex gap-2 justify-end grid">
        {dataSource?.length > 0 && (
          <div className="my-2">
            <button
              onClick={exportToExcel}
              className="px-4 py-2.5 sm:mx-0 mx-1 bg-blue-500 text-white rounded"
            >
              Excel استخراج
            </button>
          </div>
        )}
        <DateFilterr onDateFilterChange={filterMessagesByDate} />
      </div>
      <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        columns={columns}
        enableFiltering={enableFiltering}
        pagination
        cellSelection={cellSelection}
        defaultLimit={10}
        rowHeight={150}
        showZebraRows={true}
        dataSource={dataSource}
        defaultFilterValue={filters}
      />
      {isModalOpen && (
        <div className="fixed inset-0 py-4 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <button
              className="mt-12 text-red-500"
              onClick={() => setIsModalOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Preview"
                className="max-w-full max-h-screen"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGridFaulSMS;
