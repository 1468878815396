import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";

interface DeviceMasterContextType {
  loading: boolean;
  deviceData: any | null;
  fetchDeviceMaster: () => void;
}

export const DeviceMasterContext = createContext<DeviceMasterContextType>({
  deviceData: null,
  loading:false,
  fetchDeviceMaster: () => {},
});
 
const DeviceMasterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [deviceData, setDeviceData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  // Fetch the latest device master data
  const fetchDeviceMaster = useCallback(async () => {
    if (!token) return;
    setLoading(true)
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/getdevicmaster",
        new FormData(),
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          params: {
            token,
          },
        }
      );
      // console.log(response.data)
      setDeviceData(response.data);
    } catch (error) {
      console.log("Failed to fetch device master data", error);
    }
    finally {
      setLoading(false); 
    }
  }, [token]);

  useEffect(() => {
    fetchDeviceMaster();
  }, [fetchDeviceMaster]);

  return (
    <DeviceMasterContext.Provider value={{ deviceData,loading, fetchDeviceMaster }}>
      {children}
    </DeviceMasterContext.Provider>
  );
};

export default DeviceMasterProvider;
