import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaWhatsapp } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import {
  AiFillCode,
  AiFillFileImage,
} from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";
import { GiPresent } from "react-icons/gi";
import { MdGroups } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import { MdOutlineGroupWork } from "react-icons/md";
import { AiOutlineSend } from "react-icons/ai";
import { RiPriceTag3Line } from "react-icons/ri";
import { BiSolidContact } from "react-icons/bi";
import { MdOutlineAutoFixNormal } from "react-icons/md";
import { FaSquarePollVertical } from "react-icons/fa6";
import { RiDashboardHorizontalLine } from "react-icons/ri";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [sidebarExpanded] = useState(storedSidebarExpanded === "true");

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // const { rolePrivileges } = useContext(AuthContext);

  const isDashboardPath = location.pathname.startsWith("/dashboard");
  const { pathname } = location;

  const handleDropdownToggle = (id: string) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // Handle Esc key to close sidebar
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [sidebarOpen, setSidebarOpen]);

  // Close sidebar when clicking outside of it
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = () => {
    // On mobile, close the sidebar when clicked
    if (isMobile) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };

  // Close sidebar on pathname change
  useEffect(() => {
    if (sidebarExpanded) {
      setSidebarOpen(false);
    }
  }, [pathname, sidebarExpanded, setSidebarOpen]);

  // Update localStorage and body class based on sidebar state
  const updateBodyClass = (expanded: boolean) => {
    if (expanded) {
      document.body.classList.add("sidebar-expanded");
    } else {
      document.body.classList.remove("sidebar-expanded");
    }
  };

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    updateBodyClass(sidebarExpanded);
  }, [sidebarExpanded]);
  return (
    <div>
      <aside
        ref={sidebar}
        className={`absolute top-12 left-0 h-full text-gray-700 sm:bg-transparent border-r border-gray-200 py-2 transition-transform transform ${
          sidebarOpen
            ? "translate-x-0 w-64 bg-white"
            : "sm:w-20 -translate-x-full"
        } md:translate-x-0 z-40 ease-in-out duration-300 overflow-y-auto hide-scrollbar scrollbar-light`}
      >
        <div className="flex mx-2 pointer-events-auto">
          <Link to={"/dashboard"}>
            <img
              className="h-10 mb-4 bg-cover"
              src="/logo.png"
              alt="Logo"
              loading="lazy"
            />
          </Link>
        </div>
        <ul className="space-y-2">
        <React.Fragment>
            <li className="group">
              <Link
                onClick={handleClick}
                to="/dashboard"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  isDashboardPath
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <RiDashboardHorizontalLine
                  className={`w-6 h-6 mr-3 ${
                    isDashboardPath
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  لوحة التحكم
                </span>
              </Link>
            </li>
            <li className="group">
              <Link
                onClick={handleClick}
                to="/whatsapp"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  location.pathname === "/whatsapp"
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <FaWhatsapp
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/whatsapp"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  أجهزة الواتس اب
                </span>
              </Link>
            </li>
            <li className="group">
              <Link
                onClick={handleClick}
                to="/groupcontact"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  location.pathname === "/groupcontact"
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <BiSolidContact
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/groupcontact"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  جهات الاتصال
                </span>
              </Link>
            </li>
            {/* drop messages */}

            <li className="group" >
              <button
                onClick={() => handleDropdownToggle("sendcampaigns")}
                className={`flex items-center p-2 rounded-sm w-full text-left transition-colors duration-300 ${
                  location.pathname === "/sendcampaign"
                    ? "bg-gray-100 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <IoMdTime
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/sendcampaign"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  الترويج
                </span>
                {openDropdown === "sendcampaigns" ? (
                  <IoIosArrowDown className="ml-auto font-bold" />
                ) : (
                  <IoIosArrowForward className="ml-auto font-bold" />
                )}
              </button>
              <ul
                className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                  openDropdown === "sendcampaigns"
                    ? "max-h-screen opacity-100"
                    : "max-h-0 hidden opacity-0"
                }`}
              >
                <li>
                  <Link
                    onClick={handleClick}
                    to="/sendcampaign"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendcampaign"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <IoMdTime
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendcampaign"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارسال رسائل مجدولة
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/sendmessages"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendmessages"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "sm:hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <FiFileText
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendmessages"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارسال رسائل نص
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/sendmefilessages"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendmefilessages"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "sm:hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <AiFillFileImage
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendmefilessages"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارسال رسائل ملفات
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/codegroup"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/codegroup"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "sm:hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <MdOutlineAutoFixNormal
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/codegroup"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ترميز المجموعات
                    </span>
                  </Link>
                </li>
                {/* Add more group links here */}
              </ul>
            </li>

            {/* Groups Dropdown */}

            <li className="group">
              <button
                onClick={() => handleDropdownToggle("groups")}
                className={`flex items-center p-2 rounded-sm w-full text-left transition-colors duration-300 ${
                  location.pathname === "/groupsm"
                    ? "bg-gray-100 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <MdGroups
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/groupsm"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  ارسال للمجموعات
                </span>
                {openDropdown === "groups" ? (
                  <IoIosArrowDown className="ml-auto font-bold" />
                ) : (
                  <IoIosArrowForward className="ml-auto font-bold" />
                )}
              </button>
              <ul
                className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                  openDropdown === "groups"
                    ? "max-h-screen opacity-100"
                    : "max-h-0 hidden opacity-0"
                }`}
              >
                <li>
                  <Link
                    onClick={handleClick}
                    to="/groupsm"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/groupsm"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <AiOutlineSend
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/groupsm"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارسال رسائل نص للمجموعات
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/groupsendf"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/groupsendf"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "sm:hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <MdOutlineGroupWork
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/groupsendf"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارسال ملفات للمجموعات
                    </span>
                  </Link>
                </li>
                {/* Add more group links here */}
              </ul>
            </li>

            {/*  */}

            {/* pricing */}

            <li className="group">
              <button
                onClick={() => handleDropdownToggle("groupsp")}
                className={`flex items-center p-2 rounded-sm w-full text-left transition-colors duration-300 ${
                  location.pathname === "/promotions" ||
                  location.pathname === "/orders"
                    ? "bg-gray-100 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <RiPriceTag3Line
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/promotions" ||
                    location.pathname === "/orders"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  الاسعار
                </span>
                {openDropdown === "groupsp" ? (
                  <IoIosArrowDown className="ml-auto font-bold" />
                ) : (
                  <IoIosArrowForward className="ml-auto font-bold" />
                )}
              </button>
              <ul
                className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                  openDropdown === "groupsp"
                    ? "max-h-screen opacity-100"
                    : "max-h-0 hidden opacity-0"
                }`}
              >
                <li>
                  <Link
                    onClick={handleClick}
                    to="/promotions"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/promotions"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <GiPresent
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/promotions"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      الباقات
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/orders"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/orders"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-blue-50 text-gray-700"
                              : "sm:bg-blue-50 rounded-lg"
                          }`
                        : "sm:hover:bg-slate-100 text-gray-700"
                    }`}
                  >
                    <IoBagHandleOutline
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/orders"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      الطلبات
                    </span>
                  </Link>
                </li>
                {/* Add more group links here */}
              </ul>
            </li>

            {/* pricing */}

            {/* reports */}
            <li className="group">
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  setSidebarOpen(false);
                }}
                to="/reports"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  location.pathname === "/reports"
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <FaSquarePollVertical
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/reports"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-md font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  التقارير
                </span>
              </Link>
            </li>
            {/* reports */}
            <li className="group">
              <a
                href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                  location.pathname ===
                  "https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <AiFillCode className="w-6 h-6 mr-3" />
                <span
                  className={`text-lg font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  API Docs
                </span>
              </a>
            </li>

            <li className="group">
              <button
                onClick={() => {
                  handleLogout();
                }}
                className={`flex items-center p-3 w-full text-left rounded-sm transition-colors duration-300 ${
                  location.pathname === "/logout"
                    ? "bg-gray-200 text-gray-700"
                    : "hover:bg-slate-100 text-gray-700"
                }`}
              >
                <FaSignOutAlt
                  className={`w-6 h-6 mr-3 ${
                    location.pathname === "/logout"
                      ? "text-gray-700"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-lg font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  تسجيل الخروج
                </span>
              </button>
            </li>
          </React.Fragment>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
