import React, { useState, useContext, useCallback, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import { FiEdit, FiEye, FiEyeOff } from "react-icons/fi";

const EditProfile: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<string>("");
  const { usernamee, token } = useContext(AuthContext);
  const [errors, setErrors] = useState<{
    username?: string;
    password?: string;
    confirmPassword?: string;
    general?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (usernamee) {
      setUsername(usernamee);
    }
  }, [usernamee]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message) {
      timeout = setTimeout(() => setMessage(null), 1000);
    }
    return () => clearTimeout(timeout);
  }, [message]);

  const validatePasswordStrength = (password: string) => {
    if (password.length < 6) return "Weak";
    if (password.length < 12) return "Moderate";
    return "Strong";
  };

  const validate = useCallback(() => {
    const newErrors: {
      username?: string;
      password?: string;
      confirmPassword?: string;
    } = {};
    if (!username) newErrors.username = "مطلوب حقل المستخدم";
    if (!password) newErrors.password = "مطلوب حقل كلمة المرور";
    if (password !== confirmPassword)
      newErrors.confirmPassword = "كلمة المرور غير متطابقة";
    return newErrors;
  }, [username, password, confirmPassword]);

  const handleUpdateChange = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const newErrors = validate();
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/UpdateUser",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      
      // console.log(response.data);

      if (response.data.status === true) {
        // console.log(response.data);
        setTimeout(() => {
          setMessage({
            text: "تم تحديث الملف الشخصي ",
            type: "success",
          });
        }, 1000);
      } else {
        setErrors({ general: "Failed to update profile" });
      }
    } catch (error) {
      console.log("Error updating profile:", error);
      setErrors({ general: "An error occurred,Internal Server Error 500." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 selection:bg-red-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div>
          {message && (
            <p
              className={`text-sm mt-1 firework-message ${
                message.type === "error" ? "error-message" : ""
              }`}
            >
              {message.text}
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </p>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-600">
            تعديل البيانات الشخصية
          </h2>
          <FiEdit size={25} className="text-cyan-500" />
        </div>

        <form onSubmit={handleUpdateChange}>
          <div className="mb-4">
            <label className="block text-gray-700">المستخدم</label>
            <input
              type="text"
              className={`w-full p-2 border text-slate-600 hover:border-indigo-200 appearance-none rounded px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 ${
                errors.username ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {errors.username && (
              <p className="text-red-500 text-sm mt-1">{errors.username}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">كلمة المرور</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="unique-password-field-name"
                className={`w-full p-2 border text-slate-600 hover:border-indigo-200 appearance-none rounded px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } rounded mt-1`}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordStrength(validatePasswordStrength(e.target.value));
                }}
                autoComplete="new-password"
              />

              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
            <p className="text-gray-500 text-sm mt-1">
              Strength: {passwordStrength}
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">تأكيد كلمة المرور</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className={`w-full p-2 border text-slate-600 hover:border-indigo-200 appearance-none rounded px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 ${
                  errors.confirmPassword ? "border-red-500" : "border-gray-300"
                } rounded mt-1`}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-2"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">
                {errors.confirmPassword}
              </p>
            )}
          </div>

          {errors.general && (
            <p className="text-red-500 text-sm mb-4">{errors.general}</p>
          )}

          <button
            type="submit"
            className={`w-full bg-[#52c8ff] text-white p-2 rounded mt-4 ${
              isLoading ? "opacity-75 animate-fade" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "... تأكيد" : "تأكيد"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
