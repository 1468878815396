import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { GroupContactContext } from "../../../../context/contactsgroups/GroupContactContext";
// import { useNavigate } from "react-router-dom";
import DeviceSelect from "../data/DeviceSelect";
import GroupsSelectCode from "../data/GroupsSelectCode";

interface SendResult {
  status: "success" | "error";
  message: string;
}

const CreateGroupCoding: React.FC = () => {
  const { token } = useContext(AuthContext);
  const context = useContext(GroupContactContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const { fetchGroups } = context;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [code, setCode] = useState<string>("");

  const [messageError, setMessageError] = useState<string | null>(null);

  const [groupError, setGroupError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  // const navigate = useNavigate();

  const getSendResult = async (data: any) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status) {
      result = {
        status: "success",
        message: `تم إنشاء ترميز الجموعة `,
      };
      successSend = {
        text: `تم إنشاء ترميز الجموعة `,
        type: "success",
      };
    } else if (data.errNum === "401") {
      result = {
        status: "error",
        message: `هذه المجموعة غير تاابع لهذا الجهاز`,
      };
      successSend = {
        text: `هذه المجموعة غير تاابع لهذا الجهاز `,
        type: "error",
      };
    } else {
      result = { status: "error", message: `فشل في إنشاء ترميز المجموعة` };
      successSend = { text: `فشل في إنشاء ترميز المجموعة `, type: "error" };
    }

    return { sendResult: result, successSend };
  };

  const clearMessages = () => setSendResults([]);

  const resetErrors = () => {
    setMessageError(null);
    setDeviceError(null);
    setGroupError(null);
  };

  const validateInputs = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب اختيار جهاز متصل.");
      return false;
    }
    // if (!selectedGroup) {
    //   setGroupError("يجب اختيار مجموعة.");
    //   return false;
    // }
    if (!code) {
      setMessageError("اكتب ترميز المجموعة.");
      return false;
    }

    return true;
  };

  // console.log(selectedGroup?.id);

  const handleCreateGroup = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id", selectedGroup?.id);
    formData.append("code", `${code}`);
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/Code-Group",
        formData,
        {
          headers: {
            "auth-token": token,
          },
          params: {
            deviceId: selectedDevice?.deviceId,
            deviceToken: selectedDevice?.Token,
          },
        }
      );

      // console.log(response.data);
      const result = await getSendResult(response.data);
      setSendResults((prevResults) => [
        ...prevResults,
        {
          status: result.sendResult.status,
          message: result.sendResult.message,
        },
      ]);

      // If the group was created successfully, fetch groups and navigate
      if (response.data.status === true) {
        fetchGroups();
        const timeout = setTimeout(() => {
          // navigate("/groupcontact");
          return clearMessages();
        }, 3000);

        return () => clearTimeout(timeout);
      }
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-8 mx-2 flex justify-center gap-4 hide-scrollbar">
      {/* Notifications */}

      {sendResults.length > 0 && (
        <div>
          {sendResults?.map((result, index) => (
            <div
              key={index}
              className={`absolute top-16 right-8 flex gap-3 w-auto p-2  ${
                result.status === "error" ? "bg-red-500" : "bg-green-600"
              } text-white text-sm rounded-lg shadow-lg z-50 opacity-75`}
            >
              <button onClick={clearMessages} className="cursor-pointer mt-1">
                <span className="p-1 text-ةي text-gray-100">X</span>
              </button>

              <div className="mt-2">
                <p
                  className={`${
                    result.status === "error" ? "text-white" : "text-white"
                  }`}
                >
                  {result.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="sm:w-10/12 mt-6 mx-auto w-full">
        <div className="mb-6">
          <DeviceSelect onDeviceSelect={setSelectedDevice} />
          {deviceError && <p className="text-red-500 text-sm">{deviceError}</p>}
        </div>

        <div className="mb-6">
          <div className="text-end">
            <span>اختار مجموعة</span>
          </div>
          <GroupsSelectCode
            onGroupsSelect={setSelectedGroup}
            selectedOptions={selectedGroup}
            selectedDeviceId={selectedDevice?.deviceId}
          />
          {groupError && <p className="text-red-500 text-sm">{groupError}</p>}
        </div>
        <div className="my-6">
          <div className="text-end mb-2 grid space-y-1">
            <span>ترميز الجموعة</span>
          </div>
          <div>
            <input
              type="text"
              className={`w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                messageError ? "border-red-500" : ""
              }`}
              placeholder="الترميز"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {messageError && (
            <p className="text-red-500 text-sm">{messageError}</p>
          )}
        </div>
        <div className="my-4">
          <button
            type="button"
            className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleCreateGroup}
            disabled={isLoading}
          >
            {isLoading ? "إنشاء..." : "إنشاء"}
          </button>
        </div>
        {/* <div className="my-4">
          <div key={group.id}>
            <span className="border-t text-xs border-gray-200 px-2 py-2">
              <strong>{group.phone_name}</strong>
            </span>
            <span className="border-t text-xs border-gray-200 px-2 py-2">
              {group.contact_id}
            </span>
            <span className="border-t text-xs border-gray-200 px-2 py-2">
              {group.contact_id}
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CreateGroupCoding;
