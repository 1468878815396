import axios from 'axios';

interface EditPlan {
  id: string;
  title?: string;
  price?: string;
  days?: string;
  trial_days?: string;
  data?: Record<string, any>;
  is_recommended?: boolean;
  is_featured?: boolean;
  is_trial?: boolean;
  status?: string;
  isHighlighted?: number;
  buttonText?: string;
}

interface HandleUpdateChangeProps {
  editPlan: EditPlan | null;
  setLoading: (loading: boolean) => void;
  setMessage: (message: { text: string; type: 'success' | 'error' } | null) => void;
  fetchPlanMaster: () => void;
  token: string | null;
}

const handleUpdateChange = async ({
  editPlan,
  setLoading,
  setMessage,
  fetchPlanMaster,
  token,
}: HandleUpdateChangeProps) => {
  if (!editPlan) return;
  setLoading(true);
  setMessage(null);

  const formData = new FormData();
  formData.append('id', editPlan.id);
  formData.append('title', editPlan.title || '');
  formData.append('price', editPlan.price || '');
  formData.append('days', editPlan.days || '');
  formData.append('trial_days', '30');
  formData.append('plan_data', JSON.stringify(editPlan.data));
  formData.append('is_recommended', editPlan.is_recommended ? 'true' : 'false');
  formData.append('is_featured', editPlan.is_featured ? 'true' : 'false');
  formData.append('is_trial', editPlan.is_trial ? 'true' : 'false');
  formData.append('status', editPlan.status || '');
  formData.append('isHighlighted', editPlan.isHighlighted ? '1' : '0');
  formData.append('buttonText', editPlan.buttonText || '');

  try {
    const response = await axios.post(
      'https://whats1.sky.sky4system.com/api/user/StorePlan',
      formData,
      {
        headers: {
          'auth-token': token,
        },
      }
    );
    // console.log(response.data.status);
    if (response.data.status === true) {
      fetchPlanMaster();
      setMessage({
        text: 'لقد تم تحديث عرض السعر',
        type: 'success',
      });
      // Optionally, you might want to clear the form or perform other actions
    } else {
      setMessage({ text: 'Failed to update plan data.', type: 'error' });
    }
  } catch (error) {
    setMessage({
      text: 'An error occurred. Internal Server Error 500.',
      type: 'error',
    });
  } finally {
    setLoading(false);
  }
};

export default handleUpdateChange;
