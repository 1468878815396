import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";
import axios from "axios";

interface Contacts {
  pkId: number;
  sessionId: string;
  id: number;
  name: string;
  notify: string | null;
  verifiedName: string | null;
  imgUrl: string | null;
  status: string | null;
  to: string | null;
  from: string | null;
}

interface Schedule {
  id: string;
  user_id: number;
  device_id: string;
  msgId: string;
  from: string | null;
  to: string;
  type: string;
  message: string;
  send_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  contacts?: Contacts | Contacts[] | null;
}

interface MessageData {
  [x: string]: any;
  current_page: string | null;
  totalMessege: string | null;
  DeliveredMessege: string | null;
  failedMessege: string | null;
  Messages: Schedule[];
}

interface MessageApiContextType {
  Messages: MessageData | null;
  MessagesData: MessageData | null;
  loading: boolean;
  error: string | null;
  fetchMessages: (filters?: {
    status?: string;
    from_date?: string;
    to_date?: string;
    to_number?: string;
    type?: string;
  }) => Promise<void> | null; 
  // fetchMessages: () => Promise<void> | null;
  setLoadingS: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMessageById: (id: string) => Promise<void> | null;
}

interface MessageApiProviderProps {
  children: ReactNode;
}

export const MessageApiContext = createContext<
  MessageApiContextType | undefined
>(undefined);

const MessageApiProvider: React.FC<MessageApiProviderProps> = ({
  children,
}) => {
  const [Messages, setMessages] = useState<MessageData | null>(null);
  const [MessagesData, setMessagesData] = useState<MessageData | null>(null);
  const [loading, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useContext(AuthContext);

  const fetchMessages = useCallback(
    async (filters?: {
      status?: string;
      from_date?: string;
      to_date?: string;
      to_number?: string;
      type?: string;
    }) => {
      const url =
        "https://whats1.sky.sky4system.com/api/user/MesssageTransactions";

      const formData = new FormData();
      // formData.append("status", filters?.status || "");
      // formData.append("type", filters?.type || "");
      // formData.append("to_number", filters?.to_number || "");
      // formData.append("from_date", filters?.from_date || "");
      // formData.append("to_date", filters?.to_date || "");
      formData.append("Search", "");

      const headers: HeadersInit = {};
      // console.log(filters?.type)
      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          params: {
            token,
          },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;
        // console.log(data)
        if (data.status === true) {
          setMessages(data.data.Messege);
          setMessagesData(data.data);
        } else {
          setError(data.msg || "Failed to fetch Messages");
        }
      } catch (err) {
        setError("An error occurred while fetching the Messages,Internal Server Error 500.");
      } finally {
        setLoadingS(false);
      }
    },
    [token]
  );

  const fetchMessageById = useCallback(
    async (id: string) => {
      const url = "https://whats1.sky.sky4system.com/api/user/Get-Messagebyid";
      const headers: HeadersInit = {};
      const formData = new FormData();
      formData.append("id", id);

      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          // params: {
          //   token,
          // },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;

        if (data.status === true && data.data.length > 0) {
          // console.log(data);
          return data.data[0];
        } else {
          setError(data.msg || "Failed to fetch the schedule by ID");
          return null;
        }
      } catch (err) {
        setError("An error occurred while fetching the schedule by ID");
        return null;
      }
    },
    [token]
  );

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <MessageApiContext.Provider
      value={{
        Messages,
        MessagesData,
        loading,
        error,
        fetchMessageById,
        setLoadingS,
        fetchMessages,
      }}
    >
      {children}
    </MessageApiContext.Provider>
  );
};

export default MessageApiProvider;
