import React, { useContext } from "react";
import Card from "./Cart";
import {
  AiOutlineDesktop,
  AiFillContacts,
} from "react-icons/ai";
// import { UserMasterContext } from "../../context/UserMasterContext";
// import { DeviceMasterContext } from "../../context/devices/DeviceMasterContext";
// import { DeviceOrderCotext } from "../../context/devices/DeviceOrederContext";
// import { AuthContext } from "../../context/AuthContext";
// import { Link } from "react-router-dom";
// import { GiPresent } from "react-icons/gi";
// import { PlanContext } from "../../context/devices/PlanContext";
import { ContactContext } from "../../context/contactsgroups/ContactContext";
import { DashbordContext } from "../../context/DashbordContext";

const CartList: React.FC = () => {
  // const { userData } = useContext(UserMasterContext);
  // const { deviceData } = useContext(DeviceMasterContext);
  // const { orderData } = useContext(DeviceOrderCotext);
  // const { rolePrivileges } = useContext(AuthContext);
  // const { planData } = useContext(PlanContext);
  const { ContactsData } = useContext(ContactContext);
  const { dashboardData, loading } = useContext(DashbordContext);

  // console.log(rolePrivileges);
  if (loading) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-4">
        <div className="flex justify-between px-4 py-2">
          <div className="max:w-6xl py-12 px-16">Loading Dashboard...</div>
        </div>
      </div>
    );
  }
  return (
    <div className="justify-center py-3 mt-16 mr-16 px-2">
      <div className="grid grid-cols-1 bg md:grid-cols-2 min-[350px]:grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* <div className="cursor-pointer rounded-md p-2">
          {rolePrivileges === 3 ? (
            <Link
              className="border border-gray-200 mt-2 font-bold shadow-sm text-blue-500"
              to="/updatepricing"
            >
              <Card
                icon={<AiOutlineShoppingCart size={30} />}
                value={planData && planData.data ? planData.data.length : 0}
                label="عروض الاسعار"
              />{" "}
            </Link>
          ) : (
            <Link
              className="border border-gray-200 mt-2 font-bold shadow-sm text-blue-500"
              to="/dashboard"
            >
              <Card
                icon={<AiOutlineUser size={30} />}
                value={userData && userData.data ? userData.data.length : 0}
                label="عدد المستخدمين"
              />
            </Link>
          )}
        </div>
        <div className="cursor-pointer rounded-md p-2">
          {rolePrivileges === 3 ? (
            <Link
              className="border border-gray-100 font-bold shadow-sm text-blue-500"
              to="/updatepromotions"
            >
              <Card
                icon={<GiPresent size={30} />}
                value={orderData && orderData.data ? orderData.data.length : 0}
                label="تجديد الباقات"
              />
            </Link>
          ) : (
            rolePrivileges === 2 && (
              <Link
                className="border border-gray-100 font-bold shadow-sm text-blue-500"
                to="/updatepromotions"
              >
                <Card
                  icon={<AiOutlineShoppingCart size={30} />}
                  value="$0"
                  label="الدفع"
                />
              </Link>
            )
          )}
        </div> */}
        <Card
          icon={<AiOutlineDesktop size={30} />}
          value={
            dashboardData && dashboardData.data
              ? dashboardData.data.scheduleCount
              : 0
          }
          label="اجمالي الرسائل المجدولة"
        />
        <Card
          icon={<AiOutlineDesktop size={30} />}
          value={
            dashboardData && dashboardData.data
              ? dashboardData.data.messagesCount
              : 0
          }
          label="اجمالي الرسائل"
        />
         <Card
          icon={<AiFillContacts size={30} />}
          value={
            ContactsData && Array.isArray(ContactsData)
              ? ContactsData.reduce((totalCount, deviceContacts) => {
                  // Ensure `data` exists and is an array before summing its length
                  const contactsArray = deviceContacts?.data?.data;
                  return (
                    totalCount +
                    (Array.isArray(contactsArray) ? contactsArray.length : 0)
                  );
                }, 0)
              : 0
          }
          label="جهات الإتصال"
        />
        <Card
          icon={<AiOutlineDesktop size={30} />}
          value={
            dashboardData && dashboardData.data
              ? dashboardData.data.devicesCount
              : 0
          }
          label="عدد الأجهزة"
        />
      </div>
    </div>
  );
};

export default CartList;
